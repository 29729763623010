import { CapitalizeEachWord } from '../utils/StringUtils';

export const RootPublicPath = "ClientApp/public";

export function GetProductId(path) {
    const pathParts = path.split('/');
    const id = pathParts[pathParts.length - 1].split(' ', 2)[0];
    return id;
}

export function GetProductName(path) {
    const pathParts = path.split('/');
    const productName = CapitalizeEachWord(pathParts[pathParts.length - 2]);
    return productName;
}

export function GetProductColor(path) {
    const pathParts = path.split('/');
    let pathPart = pathParts[pathParts.length - 1].split(' ', 2)[1];

    if (pathPart === undefined || pathPart == null || pathPart === "undefined") {
        pathPart = pathParts[pathParts.length - 1].split('_', 2)[1];
        if (pathPart === undefined || pathPart == null || pathPart === "undefined") {
            console.log("UNDEFINDED");
            return "empty";
        }
    }

    const color = pathPart.replace(".jpg", "");
    return color;
}

export function FullyDecodeURI(path) {
    return decodeURIComponent(path).replace('+', ' ');
}