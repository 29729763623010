import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isTony: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch('/utility/istony');
      if (response.ok) {
        const data = await response.json();
        this.setState({ isTony: data });
      } else {
        console.error('Failed to retrieve isTony data.');
      }
    } catch (error) {
      console.error('An error occurred while fetching isTony data:', error);
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    const { isTony } = this.state;
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">Mockitos</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              {/*<NavItem>*/}
              {/*  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
              {/*</NavItem>*/}
              {isTony && (
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/mockups/clothing/t-shirts" replace>Clothing Mockups</NavLink>
                </NavItem>
              )}
              {/*<NavItem>*/}
              {/*  <NavLink tag={Link} className="text-dark" to="/mockups/art">Art Mockups</NavLink>*/}
              {/*</NavItem>*/}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
