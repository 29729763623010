export function CapitalizeEachWord(str) {
    let capitalizeNext = true;
    let skipNext = false;
    const result = [];

    for (const char of str) {
        if (char === ' ' || char === '/') {
            capitalizeNext = true;
            result.push(char);
        } else if (char === '-') {
            skipNext = true;
            result.push(char);
        } else {
            if (capitalizeNext) {
                result.push(char.toUpperCase());
                capitalizeNext = false;
            } else {
                if (!skipNext) {
                    result.push(char.toLowerCase());
                } else {
                    result.push(char.toUpperCase());
                    skipNext = false;
                }
            }
        }
    }

    return result.join('');
}
