import React, { useEffect, useState } from 'react';
import HtmlParser from 'html-react-parser';
import './ProductDetail.css';

const ProductDetail = ({ productId }) => {
    const [product, setProduct] = useState(null);

    useEffect(() => {
        // Create an async function to fetch product details
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`/products/getproduct/${productId}`);
                if (response.ok) {
                    const productData = await response.json();
                    setProduct(productData);
                } else {
                    console.error('Response not OK', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call the async function when the component is mounted
        fetchProductDetails();
    }, [productId]); // The effect should run whenever productId changes

    return (
        <div>
            {product ? (
                <div>
                    <div className="prod-detail">
                        <div className="flex-grid">
                            <div className="left-column">
                                <div className="prod-img-cont">
                                    <img src={product.imageUrl} alt="Product" className="prod-img" />
                                </div>
                            </div>
                            <div className="right-column">
                                <h2>{product.manufacturer} {product.model}</h2>
                                <div><strong>Type: </strong>T-Shirt Mockup</div>
                                <div><strong>Factory Color: </strong>{product.color}</div>
                                <div><strong>Model Age : </strong>{product.modelAge} Age</div>
                                <div className="prod-desc">
                                {product.description.split('\n').map((line, index) => (
                                    <p key={index}>{HtmlParser(line)}</p>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading product details...</p>
            )}
        </div>
    );
};

export default ProductDetail;
