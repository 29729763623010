import React from 'react';
import { FullyDecodeURI } from '../utils/PathUtils';
import './Breadcrumb.css';

const Breadcrumb = ({ categories }) => {
    return (
        <nav>
            <ul className="breadcrumb">
                {categories.map((category, index) => {
                    const decodedCategory = FullyDecodeURI(category);
                    return (
                        <li key={index}>
                            {index > 0 && <span className="separator">{'>'}</span>}
                            <a href={`/mockups/${decodedCategory.toLowerCase()}`}>{getLastElement(decodedCategory.split('\\'))}</a>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

function getLastElement(arr) {
    return arr[arr.length - 1];
}

export default Breadcrumb;
