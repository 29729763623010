import React, { Component } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ProductThumbnails from '../components/ProductThumbnails';
import { FullyDecodeURI } from '../utils/PathUtils';
import { CapitalizeEachWord } from '../utils/StringUtils';

export class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCategory: this.extractCategoryFromPath(window.location.pathname),
            productCategories: [],
            loading: true,
        };
    }

    extractCategoryFromPath(path) {
        const mockupsIndex = path.indexOf('/mockups/');
        if (mockupsIndex !== -1) {
            return path.substring(mockupsIndex + '/mockups/'.length);
        }
        return '';
    }

    async componentDidMount() {
        await this.updateState();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentCategory = this.extractCategoryFromPath(window.location.pathname);

        if (prevState.currentCategory === currentCategory) {
            return;
        }
        this.setState({ currentCategory }, () => {
            this.updateState();
        });
    }

    async updateState() {
        await this.populateBreadcrumbs();
        await this.populateThumbnails();
    }

    async populateBreadcrumbs() {
        try {
            const currentCategory = encodeURIComponent(this.state.currentCategory);
            const response = await fetch(`/products/getsubcategories/${currentCategory}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            this.setState({ productCategories: data, loading: false });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ loading: false });
        }
    }
    
    async populateThumbnails() {
        try {
            const currentCategory = encodeURIComponent(this.state.currentCategory);
            const response = await fetch(`/products/getproductthumbnailinfo/${currentCategory}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.length > 0) {
                this.setState({ productThumbData: data, loading: false });
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ loading: false });
        }
    }

    renderPage(state) {
        return (
            <div>
                <h1>{CapitalizeEachWord(FullyDecodeURI(state.currentCategory))}</h1>
                <Breadcrumb categories={state.productCategories} />
                <ProductThumbnails productThumbData={state.productThumbData}/>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPage(this.state);

        return (
            <div>
                {contents}
            </div>
        );
    }
}

export default Products;