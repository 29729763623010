import React from 'react';
import PaginatedGrid from './PaginatedGrid';
import ProductThumbnail from '../components/ProductThumbnail';
import './ProductThumbnails.css';

const ProductThumbnails = ({ productThumbData }) => {
    if (productThumbData === undefined)
        return <div>empty</div>;

    return (
        <div className="App">
            <PaginatedGrid itemsPerPage={9} data={productThumbData} />
        </div>
        //<div className="product-thumbnails">
        //    {filePaths.map((filePath, index) => {
        //        return (
        //            <ProductThumbnail imagePath={filePath}  />
        //        );
        //    })}
        //</div>
    );
};

export default ProductThumbnails;
