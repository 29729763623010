import React, { Component } from 'react';
import ProductDetail from '../components/ProductDetail';

export class ProductDetails extends Component {
    async componentDidMount() {
        await this.render();
    }

    extractProductIdFromPath(path) {
        const lastSlashIndex = path.lastIndexOf('/');
        if (lastSlashIndex !== -1) {
            return path.substring(lastSlashIndex + 1);
        }
        return '';
    }

    renderPage(state) {
        var productId = this.extractProductIdFromPath(window.location.pathname);

        return (
            <div>
                <ProductDetail productId={productId}/>
            </div>
        );
    }

    render() {
        let contents = this.renderPage(this.state);

        return (
            <div>
                {contents}
            </div>
        );
    }
}

export default ProductDetails;