import React, { useState } from 'react';
import Thumbnail from './Thumbnail';

const PaginatedGrid = ({ itemsPerPage, data }) => {
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the start and end indexes for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the items for the current page
    const currentItems = data.slice(startIndex, endIndex);

    // Function to handle page changes
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div>
            <div className="thumbnail-grid">
                {currentItems.map((item, index) => (
                    <Thumbnail
                        key={index}
                        title={item.title}
                        href={item.hrefUrl}
                        imageUrl={decodeURIComponent(item.imageUrl.replace(/\+/g, ' '))}
                    />
                ))}
            </div>
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={endIndex >= data.length}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PaginatedGrid;
