import { Home } from "./pages/Home";
import { Products } from "./pages/Products";
import { ProductDetails } from "./pages/ProductDetails";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/mockups/clothing/*',
    element: <Products />
  },
  {
    path: '/mockup/:makeandmodel/:color/:productid',
    element: <ProductDetails />
  },

  //{
  //  path: '/mockups/:category/:subcategory/:gender',
  //  element: <Products />
  //},
  //{
  //  path: '/mockups/:category/:subcategory',
  //  element: <Products />
  //},
  //{
  //  path: '/mockups/:category',
  //  element: <Products />
  //},
];

export default AppRoutes;
