import React from 'react';

const Thumbnail = ({ title, href, imageUrl }) => {
    return (
        <a href={href}>
            <img src={imageUrl} alt={title} title={title} className="product-thumbnail" />
            <div>{title}</div>
        </a>
    );
};

export default Thumbnail;
